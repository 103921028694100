var video
var recorder
var hasWebcam = false;

function captureCamera(callback) {

    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(function (camera) {
      callback(camera);
    }).catch(function (error) {
      console.error(error);
      window.dispatchEvent(new CustomEvent("camera", {camera: false}));
    });
}


function startRecording() {
  captureCamera(function (camera) {
    video = document.getElementById('video');
    video.muted = true;
    video.volume = 0;
    video.srcObject = camera;

    recorder = new RecordRTC(camera, {
      type: 'video'
    });

    recorder.startRecording();
    recorder.camera = camera;
  });
}
